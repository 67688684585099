import React from "react";
import { createRoot } from "react-dom/client";
import Footer from "./components/Footer";
import { ThirdwebProvider } from 'thirdweb/react';
import { bsc, bscTestnet } from 'thirdweb/chains';
import { WalletProvider } from './contexts/WalletContext';
import { ContractProvider } from './contexts/SmartContractContext';
import "./styles/Main.css";
import App from "./App";

const config = {
	defineChains: [bsc, bscTestnet],
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<ThirdwebProvider manager={config}>
			<WalletProvider>
				<ContractProvider>
					<main
						id='main'
						className='flex flex-col'>
						<App />
						<Footer />
					</main>
				</ContractProvider>
			</WalletProvider>
		</ThirdwebProvider>
	</React.StrictMode>
);
