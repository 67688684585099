import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import Hero from "./components/Hero";
import About from "./components/About";
import Header from "./components/Header";
import Howtobuy from "./components/Howtobuy";
import ShareSales from "./components/ShareSales";
import { useWallet } from "./contexts/WalletContext";

const App = () => {
	const { connectedAddress } = useWallet();
	// alert(process.env.REACT_APP_TEMPLATE_CLIENT_ID);
	const currentPath = window.location.pathname;

	const _sponsor_ = "0x43720aBf27F94E19460f0e9b3983f211903a3077";

	const queryParameters = new URLSearchParams(window.location.search);

	const sponsor_ = !Cookies.get("ref") ? _sponsor_ : Cookies.get("ref");

	const _sponsor = !queryParameters.get("ref")
		? sponsor_
		: queryParameters.get("ref");

	Cookies.set("ref", _sponsor, { expires: 30 });

	const SweetA = withReactContent(Swal);

	const [isCopied, setIsCopied] = useState(false);
	const [_affLink, setaffLink] = useState();
	
	useEffect(() => {
		let root = window.location.href.split("?")[0];
		setaffLink(`${root}?ref=${connectedAddress}`);
	}, [connectedAddress]);

	return (
		<>
			<Header />
				
			{currentPath === '/shares' ? 
				<ShareSales
					CopyToClipboard={CopyToClipboard}
					setIsCopied={setIsCopied}
					SweetA={SweetA}
					isCopied={isCopied}
					_affLink={_affLink}
					_sponsor={_sponsor}
				/>
				:
				<Hero
					CopyToClipboard={CopyToClipboard}
					setIsCopied={setIsCopied}
					SweetA={SweetA}
					isCopied={isCopied}
					_affLink={_affLink}
					_sponsor={_sponsor}
				/>
			}
			<Howtobuy
				_affLink={_affLink}
				CopyToClipboard={CopyToClipboard}
				setIsCopied={setIsCopied}
			/>
			<About />
		</>
	);
};

export default App;
