import React, { useEffect, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import Payoption from "./Payoption";
import bnbLogo from "../img/bnb.webp";
import usdtLogo from "../img/usdt.webp";
import tokenIcon from "../img/icon.png";
import Description from "./Description";
import {
	useContractRead,
	useContractWrite,
	useSmartContract,
	useWaitTransactionReceipt,
  } from '../contexts/SmartContractContext';
import { useWallet } from "../contexts/WalletContext";
import { formatEther, parseEther } from 'viem';

const Hero = ({
	_affLink,
	_sponsor,
	CopyToClipboard,
	setIsCopied,
	SweetA,
	isCopied,
}) => {

	const { connectedAddress: connected, 
		bsc, bscTestnet, useSwitchActiveWalletChain: useSwitchChain, useActiveWalletChain: activeWalletChain } = useWallet();

	const switchChain = useSwitchChain();

	const { salesContractJson } = useSmartContract();

	const getChainData = activeWalletChain();

	const [connectedChain, setConnectedChain] = useState(null);

	const [modalShow, setModalShow] = useState(false);
	const [salesPercent, setSalesPercent] = useState(0);
	const [globalStats, setGlobalStats] = useState({ sold: 0 });
	const [userDash, setUserDash] = useState({ purchased: 0 });

	const [payOption, setPayOption] = useState("bnb");
	const [amountLabel, setAmountLabel] = useState("BNB");
	const [tokenEntered, setTokenEntered] = useState(false);
	const [ctrlToBuy, setCTRLToBuy] = useState("0");
	const [amountToBuy, setAmountToBuy] = useState("0");
	const [usdtAmount, setUSDTAmount] = useState(0);

	const [tokenPrice, setTokenPrice] = useState("0.00001");

	const [buyerOption, setBuyerOption] = useState("default");

	const [bnbPrice, setBNBPrice] = useState("975.665");

	const [appvdAmount, setApprvedAmount] = useState(0);

	const hashRef = useRef(null);
	const isPurchaseRef = useRef(false);
	const isApproveRef = useRef(false);
	const approvedRef = useRef(false);

    const currentNetwork = bsc; 
	
	// reading
	const { data: tokenPriceData } = useContractRead(
		'getTokenPerUSDT',
		[],
		'sales',
	);

	const { data: bnbPriceData } = useContractRead(
		'getBNBtoUSDT',
		[],
		'sales',
	);
	  
	const { data: usersData, isLoading: loadingUser } = useContractRead(
		"users",
		[connected],
		'sales'
	);

	const { data: globalData, isLoading: loadingGlobal } = useContractRead(
		"stats",
		[],
		'sales'
	);

	// check usdt approved
	const { data: usdtApproved } = useContractRead(
		 "allowance", 
		 [connected, salesContractJson?.address],
		 'usdt'
	);

	// receipts
	const { receipt: appvdRcpt, isLoading: loadngAppvd } = useWaitTransactionReceipt('usdt', hashRef.current);
	const { receipt: transactionRcpt, isLoading: loadngRcpt } = useWaitTransactionReceipt('sales', hashRef.current);
	
	// actions
	const {
		prepareTransaction: approveToken,
		sendTx: sendApproveCall,
		transactionResult: appvTrx,
		isPending: apprvPng,
		isError: errorAppr,
	} = useContractWrite();

	const {
		prepareTransaction: makePurchase,
		sendTx: sendPurchaseCall,
		transactionResult: purchTrx,
		isPending: trctPng,
		isError: errorTrct,
	} = useContractWrite();
	

	// contract calls [buy, approve, claim tokens]
	const callContract = async (action, paymentType = 'BNB' ) => {
		if (usdtAmount < 0.00050) {
			SweetA.fire({
				title: <strong>Wrong Amount</strong>,
				html: (
					<strong>
						Amount should be greater than or equal to $50
					</strong>
				),
				icon: "error",
			});
			return;
		}
		hashRef.current = null;
		try {
			// approve
			if ( action === 'approve' || (paymentType === 'USDT' && amountToBuy >= approvedRef.current) ) {
				const transaction = await approveToken('approve', [
				  salesContractJson?.address,
				  parseEther(parseFloat(amountToBuy * 1.025).toFixed(6)),
				], 'usdt', currentNetwork);
				sendApproveCall(transaction);
				isApproveRef.current = true;
				isPurchaseRef.current = false;
			}
			else {
				const _type = buyerOption === "default" ? false : true
				// purchase token here []
				const transaction = await makePurchase(
					paymentType === 'BNB' ? 'purchase' : 'usdtPurchase',
					paymentType === 'BNB'
					  ? [_sponsor, _type]
					  : [_sponsor, parseEther(parseFloat(amountToBuy).toFixed(6)), _type],
					  'sales',
					  currentNetwork,
					paymentType === 'BNB'
					  ? parseEther(parseFloat(amountToBuy).toFixed(6))
					  : null,
				  );
				  sendPurchaseCall(transaction);
				  isApproveRef.current = false;
				  isPurchaseRef.current = true;
				  errorTrct && console.log(errorTrct)
			}
		} catch (error) {
			console.log('Error ', error);
		}
	};

	useEffect(() => {
		if (usdtApproved){
			setApprvedAmount(parseFloat(formatEther(usdtApproved)).toFixed(8));
			approvedRef.current = formatEther(usdtApproved);
		}
	}, [usdtApproved, connected]);

	// SweetAlert	
	const callSweetAlert = async (success, msg, title = null) => {
		if (success) {
			await SweetA.fire({
					icon: 'success',
					title: title ? title : 'Success!',
					text: msg,
				}).then(() => {
					hashRef.current = null;
				});
		} else {
			await SweetA.fire({
				icon: 'error',
				title: title ? title : 'Oops...',
				text: msg,
			}).then(() => {
				hashRef.current = null;
			});
		}
	};

	useEffect(() => {
		if (getChainData) {
			setConnectedChain({
				name: getChainData?.id === 56 || getChainData?.id === 97 ? getChainData?.name : "Wrong Network!",
				id: getChainData?.id,
			});
		}
	}, [getChainData]);

	useEffect(() => {
		if (bnbPriceData)
			setBNBPrice(parseFloat(formatEther(bnbPriceData)).toFixed(8));
	}, [bnbPriceData]);

	useEffect(() => {
		let _totalSold;
		if (globalData){
			_totalSold = parseFloat(formatEther(globalData[1]));
		}

		let sold = parseFloat(_totalSold);
		let toRaise = 150_000_000_000;
		let percentage = sold / toRaise;
		setGlobalStats({
			sold: sold,
		});
		console.log(percentage);
		setSalesPercent(parseFloat(percentage * 100).toFixed(2));
	}, [globalData]);

	useEffect(() => {
		let _purchased;

		if (usersData)
			_purchased = parseFloat(formatEther(usersData[2]));

		setUserDash({
			purchased: parseFloat(_purchased),
		});
	}, [usersData]);

	useEffect(() => {
		if (isCopied) {
			SweetA.fire(<span>Affiliate Link Copied!</span>);
		}
		setIsCopied(false);
	}, [isCopied]);

	useEffect(() => {
		setTokenEntered(false);
		let usdtAmount = amountToBuy;
		setAmountLabel("USDT");
		if (payOption === "bnb") {
			usdtAmount = Number(amountToBuy) * Number(bnbPrice);
			setAmountLabel("BNB");
		}
		// minimum amount $50
		if (!tokenEntered) {
			setCTRLToBuy(
				parseFloat(usdtAmount / Number(tokenPrice)).toFixed(8)
			);
		}
		setUSDTAmount(usdtAmount);
	}, [payOption, amountToBuy, tokenPrice]);

	useEffect(() => {
		if (tokenEntered) {
			let tokenAmountToBuy = Number(ctrlToBuy);
			let usdtAmount = parseFloat(
				tokenAmountToBuy * Number(tokenPrice)
			).toFixed(2);
			if (payOption === "bnb") {
				setAmountToBuy(parseFloat(usdtAmount / bnbPrice).toFixed(8));
			} else setAmountToBuy(usdtAmount);
		}
	}, [ctrlToBuy]);

	useEffect(() => {
		if (buyerOption !== "default") setTokenPrice("0.00001");
		else setTokenPrice("0.0001");
	}, [buyerOption]);


	useEffect(() => {
		if (appvdRcpt && isApproveRef.current) {
		  isApproveRef.current = false;
		  callSweetAlert(true, 'USDT Approved Successfully!', 'Approve Spend');
		}
		if (loadngRcpt && isPurchaseRef.current) {
		  isPurchaseRef.current = false;
		  callSweetAlert(
			true,
			'Token purchased Successfully!',
			'Transaction Successful',
		  );
		}
	  }, [
		appvdRcpt,
		transactionRcpt,
		loadngAppvd,
		errorAppr,
		apprvPng,
		loadngRcpt,
	  ]);
	
	  useEffect(() => {
		if (appvTrx) hashRef.current = appvTrx?.transactionHash;
		if (purchTrx) hashRef.current = purchTrx?.transactionHash;
	  }, [appvTrx, purchTrx]);

	// useEffect(() => {
	//     if(errorApprvd) SweetA.fire(errorApprvd);
	//     if(errorBNB) SweetA.fire(errorBNB);
	//     if(errorUSDT) SweetA.fire(errorUSDT);
	// }, [errorApprvd, errorBNB, errorUSDT]);
	const renderer = ({ days, hours, minutes, seconds }) => {
		return (
			<span>
				{zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m{" "}
				{zeroPad(seconds)}s
			</span>
		);
	};

	const phase = 1696258800000 + 780 * 24 * 60 * 60 * 1000;

	return (
		<>
			<section className='py-12 lg:py-24'>
				<div className='container flex flex-col gap-16 px-1 lg:flex-row lg:gap-4 lg:px-0'>
					<div
						className='relative flex w-full justify-center lg:w-1/2'
						style={{ opacity: 1, transform: "none" }}>
						<div className='relative  w-full max-w-lg overflow-hidden rounded-3xl bg-[#1B0432]/80 backdrop-blur-xl'>
							{( loadngAppvd || loadngRcpt || loadingUser || loadingGlobal || apprvPng || trctPng ) && (
								<div
									className='absolute inset-0 z-20 flex h-full w-full 
                            items-center justify-center bg-black/60 backdrop-blur-sm'
									role='status'>
									<svg
										aria-hidden='true'
										className='h-8 w-8 animate-spin fill-primary text-gray-200'
										viewBox='0 0 100 101'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
											fill='currentColor'></path>
										<path
											d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
											fill='currentFill'></path>
									</svg>
									<span className='sr-only'>Loading...</span>
								</div>
							)}
							<div className='mb-6 flex flex-col gap-6 px-5 pt-8'>
								<h4
									className='bg-gradient-to-b from-[#F320D8] to-[#16A6EE] 
                                bg-clip-text text-center text-3xl font-medium 
                                text-transparent lg:text-4xl'>
									PUBLICSALE IS NOW LIVE
								</h4>
								<div className='text-center'>
									Ends in:{" "}
									<Countdown
										date={phase}
										renderer={renderer}
									/>
								</div>
								<div className='relative h-7 overflow-hidden rounded-xl bg-white'>
									<div className='absolute z-10 flex h-full w-full items-center justify-center text-md text-black'>
										<p>
											<strong>
												{salesPercent}% Sold!
											</strong>
										</p>
									</div>
									<div
										className='absolute inset-0 z-0 bg-gradient-to-r from-[#16A6EE] to-[#F320D8]'
										style={{
											width: `${salesPercent}%`,
										}}></div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='flex items-end justify-center gap-2'>
										<b>TOKENS SOLD</b>
										<span className='text-xl'>
											<b>
												{globalStats.sold.toFixed(5)}{" "}
												CTRL
											</b>
										</span>
									</div>
								</div>
								{connectedChain && (
									<div className='flex flex-col items-center'>
										<button
											className='flex gap-2 rounded-lg border 
                                    border-white/10 bg-transparent py-2 px-4 
                                    text-center font-semibold lg:py-4'
											onClick={() => switchChain(bsc)}>
											{connectedChain.id === 56 || connectedChain.id === 97 && (
												<svg
													width='28'
													height='28'
													viewBox='0 0 28 28'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
													className='h-6 w-6'>
													<g clipPath='url(#clip0_7_306)'>
														<path
															d='M8.56214 11.7658L13.9999 6.32797L19.4403 11.7681L22.6043 8.60414L13.9999 0L5.39838 8.60156L8.56225 11.7656M0 13.9999L3.1641 10.8354L6.32797 13.9993L3.16387 17.1634L0 13.9999ZM8.56214 16.2344L13.9999 21.6719L19.4402 16.2319L22.6059 19.3942L22.6043 19.3959L13.9999 27.9999L5.39838 19.3983L5.3939 19.3938L8.56248 16.2341M21.6719 14.0013L24.836 10.8372L27.9999 14.0011L24.8359 17.1652L21.6719 14.0013Z'
															fill='#F3BA2F'></path>
														<path
															d='M17.2089 13.9983H17.2102L13.9999 10.7877L11.6269 13.16L11.3543 13.4327L10.7921 13.995L10.7876 13.9994L10.7921 14.004L13.9999 17.2122L17.2104 14.0016L17.212 13.9998L17.2091 13.9983'
															fill='#F3BA2F'></path>
													</g>
													<defs>
														<clippath id='clip0_7_306'>
															<rect
																width='28'
																height='27.9999'
																fill='white'></rect>
														</clippath>
													</defs>
												</svg>
											)}
											<span className='truncate'>
												{connectedChain.name}
											</span>
											<svg
												width='12'
												height='7'
												viewBox='0 0 12 7'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
												className='h-3 w-3'
												style={{
													position: "relative",
													top: "5px",
												}}>
												<path
													d='M1 1L6 6L11 1'
													stroke='currentColor'
													strokeWidth='2'
													strokeLinejoin='round'></path>
											</svg>
										</button>
									</div>
								)}
								<div className='rounded-full bg-gradient-to-br from-[#16A6EE] to-[#F320D8] py-1.5 px-1.5 text-center lg:text-l'>
									<p className='mb-0'>
										My Purchase ={" "}
										{Number(userDash.purchased).toFixed(6)}{" "}
										CTRL
									</p>
								</div>
								<div className="rounded-full text-center" style={{backgroundImage: 'linear-gradient(to bottom right, #2d2d4d03, #475b86)'}}><span>Min Purchase: $50 </span></div>
								<div className='flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20 '>
									
									<button
										type='button'
										className='flex w-32 items-center gap-2 rounded-xl bg-[#4B0491] py-2.5 px-4 
                                    transition-opacity duration-200 hover:opacity-75'
										onClick={() => {
											setModalShow(true);
										}}>
										{payOption === "bnb" ? (
											<>
												<img
													src={bnbLogo}
													alt='BNB'
													className='h-7 w-7 object-contain'
												/>
												<span>BNB</span>
											</>
										) : (
											<>
												<img
													src={usdtLogo}
													alt='BNB'
													className='h-7 w-7 object-contain'
												/>
												<span>USDT</span>
											</>
										)}
										<svg
											width='12'
											height='7'
											viewBox='0 0 12 7'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M1 1L6 6L11 1'
												stroke='currentColor'
												strokeWidth='2'
												strokeLinejoin='round'></path>
										</svg>
									</button>
									<div className='flex flex-1 flex-col gap-1'>
										<label className='text-sm'>
											{amountLabel} Amount{" "}
											{payOption === "bnb" &&
												`(Est. USDT = $${parseFloat(
													Number(usdtAmount)
												).toFixed(2)})`}
										</label>
										<input
											className='w-full bg-transparent text-xl outline-none'
											type='number'
											min='0'
											step='any'
											placeholder='0'
											value={amountToBuy}
											onChange={(e) => {
												setAmountToBuy(e.target.value);
												setTokenEntered(false);
											}}
										/>
									</div>
								</div>
								<div className='py-0 px-0 text-center lg:text-l'>
									<span className='text-white/90'>
										<b>~ 1 CTRL = ${tokenPrice}</b> <br />
										{/*<label htmlFor='special'>
											Swtich to{" "}
											{(buyerOption === "default"
												? "Investors"
												: "default"
											).toUpperCase()}{" "}
											Price?{" "}
										</label>
										 <input
											id='special'
											type='checkbox'
											checked={buyerOption !== "default"}
											value={
												buyerOption === "default"
													? "Investors"
													: "default"
											}
											onChange={(e) => {
												setBuyerOption(e.target.value);
											}}
										/> */}
									</span>
									{buyerOption === "Investors" && (
										<div
											className='rounded-full'
											style={{ background: "darkred" }}>
											Your CTRL Tokens will be Locked for
											540 days!
										</div>
									)}
								</div>
								<div className='flex gap-4 rounded-xl border-2 border-transparent bg-[#232C3A]/50 py-2.5 pl-2.5 pr-6 ring-4 ring-transparent focus-within:border-primary/50 focus-within:ring-primary/20'>
									<button
										type='button'
										className='flex w-32 items-center gap-2 rounded-xl bg-[#4B0491] py-2.5 px-4 transition-opacity duration-200 hover:opacity-75'
										disabled=''>
										<img
											src={tokenIcon}
											alt='CTRL'
											className='h-7 w-7 object-contain'
										/>
										<span>CTRL</span>
									</button>
									<div className='flex flex-1 flex-col gap-1'>
										<label className='text-sm'>
											CTRL Amount
										</label>
										<input
											className='w-full bg-transparent text-xl outline-none'
											type='number'
											min='0'
											step='any'
											placeholder='0.0'
											value={ctrlToBuy}
											onChange={(e) => {
												setCTRLToBuy(e.target.value);
												setTokenEntered(true);
											}}
										/>
									</div>
								</div>
								<div className='-mx-5 h-[2px] bg-[#30353A]'></div>
								{payOption === "bnb" && (
									<button
										className='text-normal 
                                relative flex w-full items-center 
                                justify-center gap-2 rounded-xl 
                                bg-gradient-to-tr from-[#16A6EE] 
                                to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl'
										type='button'
										onClick={async () => {
											await callContract('purchase');
										}}>
										{!connected
											? "Connect Wallet"
											: "PURCHASE CTRL NOW"}
									</button>
								)}
								{payOption !== "bnb" && (
									<button
										className='text-normal 
                                    relative flex w-full items-center 
                                    justify-center gap-2 rounded-xl 
                                    bg-gradient-to-tr from-[#16A6EE] 
                                    to-[#F320D8] py-4 px-6 font-semibold transition-opacity duration-200 
                                    hover:opacity-75 disabled:cursor-not-allowed disabled:opacity-80 lg:text-xl'
										type='button'
										onClick={async () => {
											Number(appvdAmount) >=
											Number(amountToBuy)
												? await callContract('purchase', 'USDT')
												: await callContract('approve');
										}}>
										{!connected
											? "Connect Wallet"
											: `${
													Number(appvdAmount) >=
													Number(amountToBuy)
														? "PURCHASE CTRL NOW"
														: "APPROVE USDT"
											  }`}
									</button>
								)}
							</div>
						</div>
					</div>
					<Description
						_affLink={_affLink}
						CopyToClipboard={CopyToClipboard}
						setIsCopied={setIsCopied}
					/>
				</div>
			</section>

			<Payoption
				modalShow={modalShow}
				setModalShow={setModalShow}
				payOption={payOption}
				setPayOption={setPayOption}
			/>
		</>
	);
};

export default Hero;
