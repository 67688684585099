import logo from "../img/logo.png";
import { useWallet } from "../contexts/WalletContext";
import { FingerPrintIcon } from '@heroicons/react/24/solid';

const Header = () => {
	const { callConnectModal, connectedAddress } = useWallet();
	return (
		<div className='container px-4 lg:px-0'>
			<div className='flex items-center justify-between py-6'>
				<a href='/'>
					<img
						src={logo}
						alt='logo'
						className='h-6 lg:h-16'
					/>
				</a>
				<div className='flex items-center lg:gap-6'>
					<nav className='hidden lg:block'>
						<ul className='flex gap-6'>
							<li>
								<a
									href='https://coinstrail.io'
									className='text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75'>
									Home
								</a>
							</li>
							<li>
								<a
									href='https://bscscan.com/token/0x0ae045f80496f638a09750813943e0208f4087db#code'
									className='text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75'>
									Contract Verified
								</a>
							</li>
							<li>
								<a
									href='/'
									className='text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75'>
									Contract Audit
								</a>
							</li>
							<li>
								<a
									href='https://coinstrail.io/docs/whitepaper.pdf'
									className='text-lg font-medium uppercase transition-opacity duration-200 hover:opacity-75'>
									White Paper
								</a>
							</li>
						</ul>
					</nav>
					<div className='flex items-center gap-2'>
						{/* <ConnectWallet /> */}
						<button
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								background: 'linear-gradient(90deg, rgba(102,51,153,1) 0%, rgba(111,45,168,1) 100%)', // Adjust the gradient to match your exact colors
								color: 'white',
								padding: '10px 20px',
								borderRadius: '20px', // Adjust for desired roundness
								boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Simple shadow for depth, modify as needed
								width: 'fit-content', // Ensures the div only takes up as much space as needed
								fontFamily: 'Arial, sans-serif', // Example font, change as needed
								fontWeight: 'bold',
								fontSize: '16px' }}
							onClick={callConnectModal}
						>
							<FingerPrintIcon className="w-5 h-5" />
							<span className="text-xs md:text-base">
							{connectedAddress
								? `${connectedAddress.slice(0, 4)}....${connectedAddress.slice(-3)}`
								: 'Connect Wallet'}
							</span>
						</button>
					</div>
				</div>
			</div>
			<div className='h-px bg-gradient-to-r from-black/0 to-white/20'></div>
		</div>
	);
};

export default Header;
