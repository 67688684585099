const SharesDescription = ({CopyToClipboard, _affLink, setIsCopied}) => {
    return(<>
        <div className="w-full lg:w-1/2" style={{opacity: 1, transform: 'none'}}>
            <h2 className="mb-6 text-3xl leading-normal lg:text-left lg:text-5xl">
                🚀 Become a Coin Trailler: <br />
                <span className="mx-3 bg-gradient-to-b from-[#F320D8] to-[#4B0491] bg-clip-text text-transparent">
                CTRL Shares Public Sale</span><br />is Now Live! 🚀
            </h2>
            <p className="mb-2 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                Unlock a World of Perpetual Benefits with CTRL Shares! <br />
                Are you looking for an investment that offers more than just financial returns? 
                <br />
                <br />📈 With CTRL Shares, you not only invest in a promising future but also become part of a thriving ecosystem dedicated to rewarding its shareholders.

                {/* <br />💼 Diverse Utility: From activating PTC packages to purchasing ad credits and shopping on our booming e-commerce platform, CTRL offers unparalleled utility.

                <br />💰 Maximize Earnings: Earn effortlessly through our dynamic Paid to Click platform and amplify your crypto holdings.

                <br />🌐 Global Reach: Join our thriving community and become part of the Coinstrail ecosystem that spans the globe. */}
                <br />
                <br />🌟 By investing in CTRL Shares, you’re not just buying a share of the profits; you’re buying into a future where technology and innovation pave the way for success. Become a part of our journey to reshape the industry. Your investment is more than just a transaction; it’s a gateway to a lifetime of value and a testament to innovative investment.
                <br />#CTRLToken #CryptoGrowth
            </p>
            <div className="mb-8 text-center font-normal leading-relaxed text-white/80 lg:text-left lg:text-xl">
                <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                    <a href="https://coinstrail.io/docs/whitepaper.pdf" target="_blank" 
                        className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr 
                        from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity 
                        duration-200 hover:opacity-75 lg:text-base" rel="noreferrer">
                        <span>Whitepaper</span>
                    </a>
                    <a href="/"
                        rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                        <span>Audit</span>
                    </a>
                    <a href="https://gopluslabs.io/token-security/56/0x0ae045f80496f638a09750813943e0208f4087db"
                        rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                        <span>Security</span>
                    </a>
                    <a href="https://bscscan.com/token/0x0ae045f80496f638a09750813943e0208f4087db"
                        rel="noreferrer" className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" target="_blank">
                        <span>Address</span>
                    </a>
                </div>
            </div>
            <div className="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-start">
                <br />
                Take advantage of our unique opportunity and earn from this public sales, Earn 10% instantly for every referrer when they use your affiliate link.
            
                <CopyToClipboard text={_affLink} onCopy={() => setIsCopied(true)}>
                    <span className="group flex items-center gap-1 rounded-lg bg-gradient-to-tr from-[#16A6EE] to-[#F320D8] py-4 px-6 text-sm font-semibold transition-opacity duration-200 hover:opacity-75 lg:text-base" > COPY AFFLIATE LINK </span>
                </CopyToClipboard>
            </div>
        </div>
    </>)
}

export default SharesDescription;