import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useConnectModal,
  useWalletDetailsModal,
  useActiveAccount,
  useAutoConnect,
  ConnectEmbed,
  useSwitchActiveWalletChain,
  useActiveWalletChain
} from 'thirdweb/react';
import { bsc, bscTestnet } from 'thirdweb/chains';
import { createWallet } from 'thirdweb/wallets';
import { client } from '../Client';

// Create a Context for the Wallet
const WalletContext = createContext();

// Provider Component
export const WalletProvider = ({ children }) => {
  const { connect } = useConnectModal();
  const detailsModal = useWalletDetailsModal();
  const activeAccount = useActiveAccount();

  const [connectedWallet, setConnectedWallet] = useState(null);
  const [connectedAddress, setConnectedAddress] = useState(null);
  // Define the wallets array
  const wallets = useMemo(
    () => [
      createWallet('io.metamask'),
      createWallet('com.trustwallet.app'),
      createWallet('me.rainbow'),
    ],
    [],
  );

  // AutoConnect the last used wallet
  const { data: autoConnected, isLoading } = useAutoConnect({
    client,
    wallets,
    timeout: 2000,
    onConnect: (wallet) => {
      if (wallet) {
        setConnectedWallet(wallet);
      }
    },
  });

  // Memorize the config object
  const config = useMemo(
    () => ({
      title: 'Connect Your Wallet',
      client: client,
      size: 'compact',
      chains: [bsc, bscTestnet],
      wallets: wallets,
      showThirdwebBranding: false,
      recommendedWallets: wallets,
    }),
    [wallets],
  );

  // Function to call connect modal
  const openConnectModal = useCallback(async () => {
    try {
      const wallet = await connect(config);
      if (wallet) {
        setConnectedWallet(wallet);
      }
    } catch (error) {
      /* empty */
    }
  }, [connect, config]);

  const callConnectModal = useCallback(async () => {
    if (connectedAddress) {
      detailsModal.open({ client });
    } else {
      openConnectModal();
    }
  }, [openConnectModal, connectedAddress, detailsModal]);

  useEffect(() => {
    setConnectedAddress(activeAccount?.address);
  }, [activeAccount]);

  return (
    <WalletContext.Provider
      value={{
        callConnectModal,
        connectedWallet,
        connectedAddress,
        ConnectEmbed,
        useSwitchActiveWalletChain,
        useActiveWalletChain,
        client,
        wallets,
        bsc,
        bscTestnet
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

// Prop validation for WalletProvider
WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the WalletContext
export const useWallet = () => useContext(WalletContext);
