import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import multiSigContractJson from '../abis/MultiSigWallet.json'; // multisig wallets
import ctrlSharesContractJson from '../abis/CoinstTrailShares.json'; // ctrl share sales
import salesContractJson from '../abis/CoinstTrailPresales.json'; // ctrl presales
import contractJson from '../abis/CoinsTrailEngine.json'; // coinstrail Engine
import usdtContractJson from '../abis/USDTToken.json';
import crtlContractJson from '../abis/CoinsTrail.json';

import { getContract, prepareContractCall } from 'thirdweb';
import { bsc, bscTestnet } from 'thirdweb/chains';
import {
  useActiveWalletChain,
  useReadContract,
  useSendTransaction,
  useWaitForReceipt,
  useWalletBalance
} from 'thirdweb/react';
import { client } from '../Client';

const SmartContractContext = createContext();

const getContractInstance = (contract, currentChain) => {
  const contractAddress = contract?.address;
  const contractAbi = contract?.abi;
  if (contractAddress && contractAbi) {
      const contractInstance = getContract({
          client,
          chain: currentChain,
          address: contractAddress,
          abi: contractAbi,
      });
      return { contractInstance };
  }

  return { contractInstance: null };
}

const useContractInstance = (contract) => {
  const getChainId = useActiveWalletChain();
  const contractAddress = contract?.address;
  const contractAbi = contract?.abi;
  const [currentChain, setCurrentChain] = useState(bsc);

  const contractInstance = useMemo(() => {
    if (contractAddress && contractAbi) {
      return getContract({
        client,
        chain: currentChain,
        address: contractAddress,
        abi: contractAbi,
      });
    }
    return null;
  }, [contractAddress, contractAbi]);

  useEffect(() => {
    getChainId && setCurrentChain(getChainId);
  }, [getChainId]);
  return { contractInstance, currentChain };
};

function getContractJson(contractType) {
  const contractsMap = {
      'admin': multiSigContractJson,
      'shares': ctrlSharesContractJson,
      'sales': salesContractJson,
      'engine': contractJson,
      'usdt': usdtContractJson,
      'ctrl': crtlContractJson
  };
  return contractsMap[contractType] || contractsMap['engine'];
}

// Custom hook to read contract data
export const useContractRead = (
  methodName,
  params = [],
  contract = 'engine'
) => {
  const contractToCall = getContractJson(contract);
  const { contractInstance } = useContractInstance(
    contractToCall
  );
  if (!contractInstance) {
    throw new Error('Contract not initialized');
  }
  const { data, isLoading, error } = useReadContract({
    contract: contractInstance,
    method: methodName,
    params: params,
  });
  return { data, isLoading, error };
};

export const useContractWrite = () => {
  const {
    mutate: sendTx,
    data: transactionResult,
    isPending,
    isError,
  } = useSendTransaction();
  const prepareTransaction = async (action, args, contract = 'engine', activeChain, amount = null) => {
    const contractToCall = getContractJson(contract);
    const { contractInstance } = getContractInstance(contractToCall, activeChain);
    const transactionParams = {
      contract: contractInstance,
      method: action,
      params: args,
      maxPriorityFeePerGas: 8n,
    };

    if (amount !== null) {
      transactionParams.value = amount;
    }

    return prepareContractCall(transactionParams);
  };
  return { prepareTransaction, sendTx, transactionResult, isPending, isError };
};

export const useWaitTransactionReceipt = (contract = 'engine', hash) => {
  const contractToCall = getContractJson(contract);
  const { currentChain } = useContractInstance(
    contractToCall
  );
  const { data: receipt, isLoading } = useWaitForReceipt({
    client: client,
    chain: currentChain,
    transactionHash: hash,
  });
  return { receipt, isLoading };
};

export const useContractBalance = (address, contract = 'usdt') => {
  const uContract = contract === 'usdt' ? usdtContractJson : contractJson;
  const { currentChain } = useContractInstance(uContract);

  const { data } = useWalletBalance({
    client,
    chain: currentChain,
    address: address,
    tokenAddress: uContract?.address,
  });
  return { data };
};

export const ContractProvider = ({ children }) => {
  return (
    <SmartContractContext.Provider
      value={{ contractJson, usdtContractJson, salesContractJson, ctrlSharesContractJson }}
    >
      {children}
    </SmartContractContext.Provider>
  );
};

ContractProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSmartContract = () => useContext(SmartContractContext);
